.call-to-action {

	// Local Variables
	$this: &;

	// Styles
	background: $white;

	&__inner {
		padding-bottom: 50px;
		padding-top: 50px;

		@include breakpoint(large) {
			padding: 80px 5%;
		}

		@include breakpoint(xxxlarge) {
			padding: 120px 96px;
		}
	}

	&__section {
		+ #{$this}__section {
			margin-top: 40px;

			@include breakpoint(large) {
				margin-top: 80px;
			}

			@include breakpoint(xxxlarge) {
				margin-top: 120px;
			}
		}
		@include breakpoint(medium) {
			align-items: flex-start;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
		}
	}

	&__intro {
		@include breakpoint(medium) {
			flex-grow: 0;
			width: 50%;
		}
	}

	&__heading {
		color: $prussianblue;
		font-size: 26px;
		font-weight: 700;
		line-height: 1.15;
		margin-bottom: 16px;

		@include breakpoint(large) {
			font-size: 40px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 64px;
			margin-bottom: 32px;
		}
	}

	&__subheading {
		color: $prussianblue;
		font-size: 18px;
		font-weight: 400;
		line-height: 1.15;
		margin-bottom: 32px;
		margin-top: -8px;
		position: relative;

		@include breakpoint(large) {
			font-size: 25px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 38px;
			margin-top: -16px;
		}
	}

	&__call {
		@include breakpoint(small down) {
			align-items: flex-end;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
		}

		@include breakpoint(medium up) {
			width: 33.333%;
		}
	}

	&__action {

		+ #{$this}__action {
			@include breakpoint(medium) {
				margin-top: 32px;
			}
		}

		@include breakpoint(small down) {
			width: calc(50% - 8px);

			.button {
				display: block;
				width: 100%;
			}

			&:only-child {
				width: 100%;

				.button {
					width: 50%;
				}
			}
		}
	}

	&__label {
		color: $gray;
		font-size: 10px;
		line-height: 1.5;
		margin-bottom: 8px;

		@include breakpoint(small) {
			font-size: 12px;
		}

		@include breakpoint(large) {
			font-size: 18px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 28px;
			margin-bottom: 16px;
		}
	}

	.about + & {
		#{$this}__inner {
			padding-top: 0;

			@include breakpoint(large) {
				padding-top: 0;
			}

			@include breakpoint(xxxlarge) {
				padding-top: 0;
			}
		}
	}
}
