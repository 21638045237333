.masthead {

	// Local Variables
	$this: &;

	// Styles
	background-color: $prussianblue;
	color: $white;

	&__inner {
		margin: 0 auto;
		max-width: 1920px;
		position: relative;
		width: 100%;

		@include breakpoint(medium) {
			align-items: stretch;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
		}
	}

	&__content {
		padding: 80px 20px 60px;
		position: relative;
		z-index: 10;

		@include breakpoint(medium) {
			width: 50%;
		}

		@include breakpoint(large) {
			padding: 70px 10% 180px 5%;
			width: 64%;
		}

		@include breakpoint(xxxlarge) {
			padding: 140px 10% 180px 5%;
		}
	}

	&__heading {
		font-size: 40px;
		font-weight: 500;
		line-height: 1;
		margin: 0 0 8px;

		[lang='es'] & {
			@include breakpoint(large) {
				font-size: 57px;
			}

			@include breakpoint(xlarge) {
				font-size: 60px;
			}

			@include breakpoint(xxxlarge) {
				font-size: 90px;
			}
		}

		@include breakpoint(large) {
			font-size: 60px;
			margin-bottom: 12px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 100px;
			margin-bottom: 32px;
		}
	}

	&__copy {
		font-size: 18px;
		line-height: 1.333;
		max-width: 240px;

		> *:first-child {
			margin-top: 0;
		}

		> *:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(large) {
			font-size: 26px;
			max-width: 80%;
		}

		@include breakpoint(xxxlarge) {
			font-size: 42px;
		}
	}

	&__background {
		@extend %overlay;
		background-position: top center;
		background-size: cover;
		z-index: 1;

		&:after {
			@extend %overlay;
			background-image: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.8) 100%);
			content: '';
			z-index: 2;
		}

		@include breakpoint(medium) {
			flex-shrink: 0;
			height: auto;
			position: relative;
			width: 50%;

			&:after {
				display: none;
			}
		}

		@include breakpoint(large) {
			width: 36%;
		}
	}

	&__image {
		display: none;
		position: absolute;
		z-index: 3;

		&--small {
			@include breakpoint(large down) {
				bottom: -33.5px;
				display: block;
				height: 230px;
				right: 20px;
				width: 67px;
			}
		}

		&--large {
			@include breakpoint(xlarge) {
				display: block;
				height: auto;
				right: calc(36% - 135px);
				top: 70px;
				width: 350px;
			}

			@include breakpoint(xxxlarge) {
				height: auto;
				right: calc(36% - 200px);
				top: 140px;
				width: 497px;
			}
		}
	}
}
