.contact {

	// Local Variables
	$this: &;

	// Styles
	background: $prussianblue;
	color: $white;
	text-align: center;

	&__inner {
		padding-bottom: 40px;
		padding-top: 40px;

		@include breakpoint(large) {
			padding-bottom: 80px;
			padding-top: 80px;
		}

		@include breakpoint(xxxlarge) {
			padding-bottom: 120px;
			padding-top: 120px;
		}
	}

	&__heading {
		color: $turquoise;
		font-size: 24px;
		font-weight: 700;
		letter-spacing: 0.025em;
		line-height: 1.15;
		margin-bottom: 16px;

		@include breakpoint(large) {
			font-size: 40px;
			margin-bottom: 24px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 64px;
			font-weight: 500;
			margin-bottom: 32px;
		}
	}

	&__copy {
		font-size: 12px;
		line-height: 1.2;
		margin-bottom: 32px;

		> &:first-child {
			margin-top: 0;
		}

		> &:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(large) {
			font-size: 20px;
			margin-bottom: 30px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 38px;
			margin-bottom: 40px;
		}
	}
}
