.about {

	// Local Variables
	$this: &;

	// Styles
	background: $white;
	text-align: center;

	&__inner {
		padding-bottom: 50px;
		padding-top: 50px;

		@include breakpoint(large) {
			padding-bottom: 80px;
			padding-top: 80px;
		}

		@include breakpoint(xxxlarge) {
			padding-bottom: 120px;
			padding-top: 120px;
		}
	}

	&__heading {
		color: $prussianblue;
		font-size: 28px;
		font-weight: 700;
		letter-spacing: -0.02em;
		line-height: 1.15;
		margin-bottom: 16px;

		@include breakpoint(medium) {
			br {
				display: none;
			}
		}

		@include breakpoint(large) {
			font-size: 40px;
			margin-bottom: 24px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 64px;
			font-weight: 500;
			margin-bottom: 32px;
		}
	}

	&__copy {
		color: $prussianblue;
		font-size: 16px;
		line-height: 1.2;
		margin-bottom: 32px;

		> &:first-child {
			margin-top: 0;
		}

		> &:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(large) {
			font-size: 24px;
			margin-bottom: 30px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 40px;
			margin-bottom: 40px;
		}
	}

	&__subheading {
		border-top: 2px solid $prussianblue;
		color: $prussianblue;
		font-size: 24px;
		font-weight: 500;
		line-height: 1;
		margin-top: 1em;
		padding-top: 1em;

		@include breakpoint(large) {
			font-size: 32px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 42px;
		}
	}

	&__brand-list {
		align-items: center;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-evenly;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	&__brand-item {
		@include breakpoint(small only) {
			flex-basis: 50%;
		}
	}

	&__brand-image {
		height: 150px;
		pointer-events: none;
		width: 150px;

		@include breakpoint(medium only) {
			height: 120px;
			width: 120px;
		}

		@include breakpoint(xxxlarge) {
			height: 250px;
			width: 250px;
		}
	}
}
