.selector {

	// Local Variables
	$this: &;
	margin-bottom: 30px;
	position: relative;
	z-index: 10;

	// Styles

	&:hover,
	&:focus,
	&:focus-within,
	&:active {
		#{$this}__initial {
			background-color: $turquoise;
			border-color: $turquoise;
			color: $prussianblue;
		}

		#{$this}__icon {
			filter: brightness(1);
		}
	}

	&__initial {
		align-items: center;
		border: 2px solid $white;
		border-radius: 4px;
		color: $white;
		cursor: pointer;
		display: inline-flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		line-height: 1;
		min-width: 150px;
		padding: 12px 12px 12px 20px;
		text-align: center;
		transition-duration: $transition-time;
		transition-property: background, border-color, color;
		transition-timing-function: ease;

		&:after {
			content: '▼';
			display: inline-block;
			margin-left: auto;
			transition-duration: $transition-time;
			transition-property: transform;
			transition-timing-function: ease;
		}

		&.open {
			background-color: $turquoise;
			border-color: $turquoise;
			color: $prussianblue;

			&:after {
				transform: rotate(180deg);
			}

			#{$this}__icon {
				filter: brightness(1);
			}

			+ #{$this}__list {
				opacity: 1;
				visibility: visible;
			}
		}

		@include breakpoint(large) {
			border-radius: 6px;
			border-width: 3px;
			min-width: 200px;
		}

		@include breakpoint(xxxlarge) {
			border-radius: 8px;
			border-width: 4px;
			min-width: 300px;
			padding: 16px 16px 16px 28px;
		}
	}

	&__icon {
		filter: saturate(0) brightness(10) contrast(2);
		height: 20px;
		margin-right: 8px;
		transition-duration: $transition-time;
		transition-property: filter;
		transition-timing-function: ease;
		width: 20px;

		@include breakpoint(large) {
			height: 28px;
			margin-right: 12px;
			width: 28px;
		}

		@include breakpoint(xxxlarge) {
			height: 40px;
			margin-right: 16px;
			width: 40px;
		}
	}

	&__text {
		font-size: 14px;
		line-height: 1;

		@include breakpoint(large) {
			font-size: 18px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 28px;
		}
	}

	&__list {
		background: $turquoise;
		// display: none;
		list-style-type: none;
		list-style-type: none;
		margin: 0;
		margin-top: -4px;
		min-width: 150px;
		opacity: 0;
		padding: 12px 20px;
		position: absolute;
		transition-duration: $transition-time;
		transition-property: opacity;
		transition-timing-function: ease;
		visibility: hidden;
		z-index: 8;

		@include breakpoint(large) {
			min-width: 200px;
		}

		@include breakpoint(xxxlarge) {
			margin-top: -8px;
			min-width: 300px;
			padding: 16px 28px;
		}
	}

	&__item {
		+ #{$this}__item {
			margin-top: 8px;

			@include breakpoint(large) {
				margin-top: 14px;
			}

			@include breakpoint(xxxlarge) {
				margin-top: 20px;
			}
		}
	}

	&__link {
		font-size: 14px;
		line-height: 1.5;
		line-height: 1;
		padding-left: 32px;

		&:link,
		&:visited {
			color: $prussianblue;
			text-decoration: none;
		}

		&:hover,
		&:focus,
		&:active {
			color: $black;
			text-decoration: underline;
		}

		@include breakpoint(large) {
			font-size: 18px;
			padding-left: 44px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 28px;
			padding-left: 60px;
		}
	}

	@include breakpoint(large) {
		margin-bottom: 50px;
	}

	@include breakpoint(xxxlarge) {
		margin-bottom: 100px;
	}
}
