.button {

	// Local Variables
	$this: &;

	// Styles

	border: 2px solid;
	border-radius: 4px;
	display: inline-block;
	line-height: 1;
	min-width: 140px;
	padding: 12px 20px;
	text-align: center;
	transition-duration: $transition-time;
	transition-property: background, border-color, color;
	transition-timing-function: ease;

	span {
		pointer-events: none;
	}

	&__max {
		height: 16px;
		position: relative;
		top: -1px;
		vertical-align: middle;
		width: 46px;

		@include breakpoint(large) {
			height: 28px;
			width: 80px;
		}

		@include breakpoint(xxxlarge) {
			height: 50px;
			width: 142px;
		}
	}

	&__text {
		font-size: 18px;
		font-weight: 500;

		@include breakpoint(large) {
			font-size: 24px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 36px;
		}
	}

	&--ghost-dark {
		&:link,
		&:visited {
			background-color: transparent;
			border-color: $prussianblue;
			color: $prussianblue;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: $turquoise;
			border-color: $turquoise;
			color: $prussianblue;
		}
	}

	&--ghost-light {
		&:link,
		&:visited {
			background-color: transparent;
			border-color: $turquoise;
			color: $turquoise;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: $turquoise;
			border-color: $turquoise;
			color: $prussianblue;
		}
	}

	&--solid-dark {
		&:link,
		&:visited {
			background-color: $prussianblue;
			border-color: $prussianblue;
			color: $white;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: $turquoise;
			border-color: $turquoise;
			color: $prussianblue;
		}
	}

	&--small {
		min-width: 100px;
		padding: 8px 20px;

		#{$this}__text {
			font-size: 14px;
			font-weight: 700px;

			@include breakpoint(large) {
				font-size: 20px;
			}

			@include breakpoint(xxxlarge) {
				font-size: 32px;
			}
		}

		@include breakpoint(large) {
			padding: 16px 32px;
		}

		@include breakpoint(xxxlarge) {
			padding: 28px 60px;
		}
	}

	@include breakpoint(large) {
		border-radius: 6px;
		border-width: 3px;
		line-height: 28px;
		min-width: 200px;
		padding: 20px 32px;
	}

	@include breakpoint(xxxlarge) {
		border-radius: 8px;
		border-width: 4px;
		line-height: 42px;
		min-width: 360px;
		padding: 36px 60px;
	}
}
