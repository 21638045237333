.equipment {

	// Local Variables
	$this: &;

	// Styles
	background-color: $springwood;

	&__inner {
		padding-bottom: 40px;
		padding-top: 40px;

		@include breakpoint(medium) {
			align-items: flex-start;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-evenly;
			padding-top: 0;
		}

		@include breakpoint(large) {
			padding-bottom: 80px;
		}

		@include breakpoint(xxxlarge) {
			padding-bottom: 120px;
		}
	}

	&__item {
		display: block;
		text-align: center;
		transition-duration: $transition-time;
		transition-property: color;
		transition-timing-function: ease;
		width: 225px;

		&:link,
		&:visited {
			color: $prussianblue;
		}

		&:hover,
		&:focus,
		&:active {
			color: $black;
		}

		@include breakpoint(small down) {
			margin: 0 auto;

			+ #{$this}__item {
				margin-top: 40px;
			}
		}

		@include breakpoint(large) {
			width: 275px;
		}

		@include breakpoint(xxxlarge) {
			width: 350px;
		}
	}

	&__image {
		height: auto;
		margin-bottom: 16px;
		width: 100%;

		@include breakpoint(large) {
			margin-bottom: 20px;
		}

		@include breakpoint(xxxlarge) {
			margin-bottom: 32px;
		}
	}

	&__title {
		font-size: 24px;
		font-weight: 500;
		line-height: 1;

		@include breakpoint(large) {
			font-size: 32px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 42px;
		}
	}

	@include breakpoint(medium) {
		background-image: linear-gradient(180deg, $white 112.5px, $springwood 112.5px);
	}

	@include breakpoint(large) {
		background-image: linear-gradient(180deg, $white 137.5px, $springwood 137.5px);
	}

	@include breakpoint(xxxlarge) {
		background-image: linear-gradient(180deg, $white 175px, $springwood 175px);
	}
}
