.logo {

	&__image {
		height: 20px;
		margin: 0 auto;
		width: 150px;

		@include breakpoint(medium) {
			height: 29px;
			width: 200px;
		}

		@include breakpoint(large) {
			height: 34px;
			width: 240px;
		}

		@include breakpoint(xxxlarge) {
			height: 64px;
			width: 450px;
		}
	}
}
