.global-header {
	background: $white;
	padding: 10px 16px;
	text-align: center;

	a + a {
		&:before {
			background-color: $prussianblue;
			content: '';
			display: inline-block;
			height: 20px;
			margin: 0 0 0 1ch;
			vertical-align: middle;
			width: 2px;

			@include breakpoint(medium) {
				height: 29px;
			}

			@include breakpoint(large) {
				height: 34px;
			}

			@include breakpoint(xxxlarge) {
				height: 64px;
			}
		}
	}

	@include breakpoint(medium) {
		padding: 20px 5%;
	}

	@include breakpoint(xxxlarge) {
		padding: 40px 10%;
	}
}
