.container {

}

.wrapper {
	display: block;
	margin: 0 auto;
	max-width: 1920px;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;

	@include breakpoint(large) {
		padding-left: 5%;
		padding-right: 5%;
	}

	@include breakpoint(xxxlarge) {
		padding-left: 96px;
		padding-right: 96px;
	}
}
