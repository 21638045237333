.introduction {

	// Local Variables
	$this: &;

	// Styles
	background: $white;
	text-align: center;

	&__inner {
		padding-bottom: 50px;
		padding-top: 50px;

		@include breakpoint(large) {
			padding-bottom: 80px;
			padding-top: 80px;
		}

		@include breakpoint(xxxlarge) {
			padding-bottom: 120px;
			padding-top: 120px;
		}
	}

	&__heading {
		color: $prussianblue;
		font-size: 28px;
		font-weight: 700;
		letter-spacing: -0.02em;
		line-height: 1.15;
		margin-bottom: 16px;

		@include breakpoint(large) {
			font-size: 40px;
			margin-bottom: 24px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 64px;
			font-weight: 500;
			margin-bottom: 32px;
		}
	}

	&__copy {
		color: $prussianblue;
		font-size: 16px;
		line-height: 1.2;
		margin-bottom: 32px;

		> &:first-child {
			margin-top: 0;
		}

		> &:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(large) {
			font-size: 24px;
			margin-bottom: 30px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 40px;
			margin-bottom: 40px;
		}
	}

	&__call {
		margin: 0 auto;
		text-align: left;

		@include breakpoint(medium down) {
			width: 280px;
		}

		@include breakpoint(large) {
			align-items: flex-end;
			display: flex;
			flex-flow: row nowrap;
			gap: 16px;
			justify-content: center;
			margin: 0 auto;
			width: 90%;
		}

		@include breakpoint(xlarge) {
			width: 85%;
		}

		@include breakpoint(xxlarge) {
			width: 80%;
		}

		@include breakpoint(xxxlarge) {
			width: 75%;
		}
	}

	&__action {

		+ #{$this}__action {
			@include breakpoint(medium) {
				margin-top: 32px;
			}
		}

		@include breakpoint(medium down) {

			.button {
				display: block;
				width: 100%;
			}

			&:only-child {
				width: 100%;

				.button {
					width: 50%;
				}
			}
		}

		@include breakpoint(large) {
			width: 50%;

			.button {
				width: 100%;
			}
		}
	}

	&__label {
		color: $gray;
		font-size: 10px;
		line-height: 1.5;
		margin-bottom: 8px;

		@include breakpoint(small) {
			font-size: 12px;
		}

		@include breakpoint(large) {
			font-size: 18px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 28px;
			margin-bottom: 16px;
		}
	}
}
